<template>
  <div>
    <b-card>
      <b-tabs
        pills
        align="left"
      >
        <b-tab
          v-for="(faq, index) in faqs"
          :key="faq.id"
        >
          <template #title>
            <span>{{ faq.category }} </span>
          </template>
          <b-card-text>
            <app-collapse
              accordion
              type="border"
            >
              <app-collapse-item
                v-for="(item, key) in faqs[index].items"
                :key="key"
                :title="item.title"
              >
                {{ item.content }}
              </app-collapse-item>
            </app-collapse>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BTabs, BTab, BCardText,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTabs,
    BTab,
    BCardText,
    AppCollapse,
    AppCollapseItem,
  },
  computed: {
    faqs() {
      return this.$store.getters['faqApp/getFaqs']
    },
  },
  created() {
    this.getFaqs()
  },
  methods: {
    getFaqs() {
      this.$store.dispatch('faqApp/faqsList')
    },
  },
}
</script>

<style scoped>

</style>
